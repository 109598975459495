import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState, useEffect, useCallback } from "react";
import { TableBody, TableFooter } from "@mui/material";
import Table from "@mui/material/Table";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TablePagination from "@mui/material/TablePagination";
import Collapse from "@mui/material/Collapse";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import PostAddIcon from "@mui/icons-material/PostAdd";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import styled from "@mui/material/styles/styled";
import PersonIcon from "@mui/icons-material/Person";
import DownloadIcon from "@mui/icons-material/Download";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import ReactPlayer from "react-player";
import { CSVLink } from "react-csv";
import { useAdminFetch } from "../../api/fetch";
import { LectureAdd } from "../../components/admin/LectureAdd";
import { VideoAdd } from "../../components/admin/VideoAdd";

const UnAttendedDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    maxWidth: "1000px",
    maxHeight: "700px",
    width: "100%",
    height: "100%",
    borderRadius: "20px",
  },
}));

const VideoDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    maxWidth: "1000px",
    maxHeight: "500px",
    width: "100%",
    height: "100%",
    borderRadius: "20px",
  },
}));

export default function LectureList() {
  const [lectureData, setLectureData] = useState<any[]>([]);
  const [unattendedLectureData, setUnattendedLectureData] = useState<any[]>([]);
  const [unattendedVideoData, setUnattendedVideoData] = useState<any[]>([]);
  const { get, post } = useAdminFetch();

  const refresh = useCallback(async () => {
    get<{
      message: string;
      lectures: any[];
    }>(`/api/admin/lectures/videos`)
      .then((res) => {
        console.log(res.message);
        setLectureData(res.lectures);
      })
      .catch(() => {
        alert("Can't get data from db properly.");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDetail, setOpenDetail] = useState<any>({});
  const [openLectureUnattendedDialog, setOpenLectureUnattendedDialog] =
    useState(false);
  const [openVideoUnattendedDialog, setOpenVideoUnattendedDialog] =
    useState(false);
  const [lectureName, setLectureName] = useState("");
  const [lectureVideoName, setLectureVideoName] = useState("");
  const [pageLectureUnattended, setPageLectureUnattended] = useState(0);
  const [rowsPerPageLectureUnattended, setRowsPerPageLectureUnattended] =
    useState(5);
  const [pageVideoUnattended, setPageVideoUnattended] = useState(0);
  const [rowsPerPageVideoUnattended, setRowsPerPageVideoUnattended] =
    useState(5);
  const [openVideoDialog, setOpenVideoDialog] = useState(false);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [content, setContent] = useState<string | null>(null);

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePageLectureUnattended = (event: any, newPage: any) => {
    setPageLectureUnattended(newPage);
  };

  const handleChangeRowsPerPageLectureUnattended = (event: {
    target: { value: string };
  }) => {
    setRowsPerPageLectureUnattended(parseInt(event.target.value, 10));
    setPageLectureUnattended(0);
  };

  const handleChangePageVideoUnattended = (
    event: any,
    newPage: React.SetStateAction<number>
  ) => {
    setPageVideoUnattended(newPage);
  };

  const handleChangeRowsPerPageVideoUnattended = (event: {
    target: { value: string };
  }) => {
    setRowsPerPageVideoUnattended(parseInt(event.target.value, 10));
    setPageVideoUnattended(0);
  };

  const toggleDetail = (lectureId: string | number) => {
    setOpenDetail((prevState: { [x: string]: any }) => ({
      ...prevState,
      [lectureId]: !prevState[lectureId],
    }));
  };

  const handleOpenLectureUnattendedDialog = (
    id: any,
    lectureName: React.SetStateAction<string>
  ) => {
    setOpenLectureUnattendedDialog(true);
    setLectureName(lectureName);
    getUnattendedLectureData(id);
  };

  const handleCloseLectureUnattendedDialog = () => {
    setOpenLectureUnattendedDialog(false);
  };

  const handleOpenVideoUnattendedDialog = (
    id: any,
    lectureName: React.SetStateAction<string>,
    lectureVideoName: React.SetStateAction<string>
  ) => {
    setOpenVideoUnattendedDialog(true);
    setLectureName(lectureName);
    setLectureVideoName(lectureVideoName);
    getUnattendedVideoData(id);
  };

  const handleCloseVideoUnattendedDialog = () => {
    setOpenVideoUnattendedDialog(false);
  };

  const handleOpenVideoDialog = (content: string, fileName: any) => {
    setContent(content);
    getVideo(fileName);
    setOpenVideoDialog(true);
  };

  const handleCloseVideoDialog = () => {
    setOpenVideoDialog(false);
    setVideoUrl("");
  };

  const onErrorTime = (error: any) => {
    if (error.code === 2) {
      alert("動画のセッションが切れました。");
      handleCloseVideoDialog();
    }
    const time = new Date();
    const japanTime = new Date(time.getTime() + 9 * 60 * 60 * 1000);
    const errorTime = japanTime.toISOString();
    console.log("Error has Occured  :" + errorTime);
    console.log("Error Code  :" + error.code);
    console.log("Error" + error);
  };

  const getVideo = (fileName: string) => {
    get<{
      url: string;
    }>(`/api/admin/video/` + fileName)
      .then((res) => {
        console.log(res.url);
        setVideoUrl(res.url);
      })
      .catch(() => alert("Can't get Video from db properly."));
  };

  const getUnattendedLectureData = async (id: any) => {
    get<{
      message: string;
      unattendedLectureData: any[];
    }>(`/api/admin/lecture/unattended/${id}`)
      .then((res) => {
        console.log(res.message);
        setUnattendedLectureData(res.unattendedLectureData);
      })
      .catch(() => {
        alert("Can't get data from db properly.");
      });
  };

  const getUnattendedVideoData = async (id: any) => {
    get<{
      message: string;
      unattendedVideoData: any[];
    }>(`/api/admin/video/unattended/${id}`)
      .then((res) => {
        console.log(res.message);
        setUnattendedVideoData(res.unattendedVideoData);
      })
      .catch(() => {
        alert("Can't get data from db properly.");
      });
  };

  const tbody = lectureData ? (
    lectureData
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((row: any) => {
        const videosNumber = row.videos.length;
        const handleDragEnd = (result: any) => {
          if (!result.destination) {
            return;
          }
          const items = Array.from(row.videos);
          const [reorderedItem] = items.splice(result.source.index, 1);
          items.splice(result.destination.index, 0, reorderedItem);
          const newLectureData = [...lectureData];
          (newLectureData as any).find(
            (lecture: any) => lecture.id === row.id
          ).videos = items;
          const videoOrder = items.map((video: any) => video.id);
          items.forEach((video: any, index) => {
            video.order = index + 1;
          });
          try {
            post<{
              message: string;
              success: boolean;
            }>(`/api/admin/video/update`, {
              body: JSON.stringify({
                lectureId: row.id,
                videoOrder: JSON.stringify(videoOrder),
              }),
            })
              .then((res) => {
                console.log(res.message);
                if (res.success) {
                  setLectureData(newLectureData);
                  setVideoOrderSuccess(true);
                } else {
                  setVideoOrderFail(true);
                }
              })
              .catch(() => {
                setVideoOrderFail(true);
              });
          } catch (error) {
            console.error("Error:", error);
            setVideoOrderFail(true);
          }
        };
        return (
          <React.Fragment key={row.id}>
            <TableRow>
              <TableCell>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => toggleDetail(row.id)}
                  sx={{ width: "10%" }}
                >
                  {openDetail[row.id] ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
              </TableCell>
              <TableCell align="center" sx={{ width: "45%" }}>
                <Typography variant="body2" fontWeight="bold">
                  {row.lectureName}
                </Typography>
              </TableCell>
              <TableCell align="center" sx={{ width: "15%" }}>
                <Typography variant="body2" fontWeight="bold">
                  {videosNumber}
                </Typography>
              </TableCell>
              <TableCell align="center" sx={{ width: "15%" }}>
                <Typography variant="body2" fontWeight="bold">
                  {row.studentsNumber !== 0
                    ? row.attendedStudents + "/" + row.studentsNumber
                    : "-"}
                </Typography>
              </TableCell>
              <TableCell align="center" sx={{ width: "15%" }}>
                <Button
                  variant="contained"
                  sx={{
                    color: "white",
                    backgroundColor: "#80c3c6",
                    borderRadius: "20px",
                    "&:hover": {
                      backgroundColor: "#80c3c6",
                    },
                    fontWeight: "bold",
                  }}
                  onClick={() =>
                    handleOpenLectureUnattendedDialog(row.id, row.lectureName)
                  }
                >
                  <PersonIcon />
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0 }}
                colSpan={6}
              >
                <Collapse in={openDetail[row.id]} timeout="auto" unmountOnExit>
                  <Box sx={{ margin: 1 }}>
                    <DragDropContext onDragEnd={handleDragEnd}>
                      <Droppable droppableId={`videos-${row.id}`}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            <Table
                              size="small"
                              aria-label="videos"
                              sx={{ border: "1px solid #E5E4E2" }}
                            >
                              <TableHead style={{ backgroundColor: "#E5E4E2" }}>
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    sx={{ width: "10%" }}
                                  >
                                    順番
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{ width: "40%" }}
                                  >
                                    動画名
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{ width: "15%" }}
                                  >
                                    プレビュー
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{ width: "10%" }}
                                  >
                                    視聴状況
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{ width: "10%" }}
                                  >
                                    回答状況
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{ width: "15%" }}
                                  >
                                    未完了者一覧
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {row.videos.map((video: any, index: number) => (
                                  <Draggable
                                    key={video.id}
                                    draggableId={`video-${video.id}`}
                                    index={index}
                                  >
                                    {(provided) => (
                                      <TableRow
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        key={video.id}
                                        sx={{ border: "1px solid #E5E4E2" }}
                                      >
                                        <TableCell
                                          align="center"
                                          sx={{ width: "10%" }}
                                        >
                                          <Typography
                                            variant="body2"
                                            fontWeight="bold"
                                          >
                                            {video.order}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          sx={{ width: "40%" }}
                                        >
                                          <Typography
                                            variant="body2"
                                            fontWeight="bold"
                                          >
                                            {video.videoName}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          sx={{ width: "15%" }}
                                        >
                                          <IconButton
                                            aria-label="play"
                                            size="large"
                                            onClick={() =>
                                              handleOpenVideoDialog(
                                                video.videoName,
                                                video.fileName
                                              )
                                            }
                                          >
                                            <PlayCircleIcon fontSize="medium" />
                                          </IconButton>
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          sx={{ width: "10%" }}
                                        >
                                          <Typography
                                            variant="body2"
                                            fontWeight="bold"
                                          >
                                            {row.studentsNumber !== 0
                                              ? video.watchedStudents +
                                                "/" +
                                                row.studentsNumber
                                              : "-"}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          sx={{ width: "10%" }}
                                        >
                                          <Typography
                                            variant="body2"
                                            fontWeight="bold"
                                          >
                                            {row.studentsNumber !== 0
                                              ? video.answeredStudents +
                                                "/" +
                                                row.studentsNumber
                                              : "-"}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          sx={{ width: "15%" }}
                                        >
                                          <Button
                                            variant="contained"
                                            sx={{
                                              color: "white",
                                              backgroundColor: "#80c3c6",
                                              borderRadius: "20px",
                                              "&:hover": {
                                                backgroundColor: "#80c3c6",
                                              },
                                              fontWeight: "bold",
                                            }}
                                            onClick={() =>
                                              handleOpenVideoUnattendedDialog(
                                                video.id,
                                                row.lectureName,
                                                video.videoName
                                              )
                                            }
                                          >
                                            <PersonIcon />
                                          </Button>
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </TableBody>
                            </Table>
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </React.Fragment>
        );
      })
  ) : (
    <TableRow>
      <TableCell align="center" colSpan={4}>
        データがありません
      </TableCell>
    </TableRow>
  );

  const [videoOrderSuccess, setVideoOrderSuccess] = useState(false);
  const [videoOrderFail, setVideoOrderFail] = useState(false);

  const [openLectureAddDialog, setOpenLectureAddDialog] = useState(false);
  const [openVideoAddDialog, setOpenVideoAddDialog] = useState(false);
  const [csvHeaders, setCsvHeaders] = useState<any[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);
  const csvLink = React.createRef<any>();

  const handleOpenLectureAdd = () => {
    setOpenLectureAddDialog(true);
  };

  const handleCloseLectureAdd = () => {
    setOpenLectureAddDialog(false);
  };

  const handleOpenVideoAdd = () => {
    setOpenVideoAddDialog(true);
  };

  const handleCloseVideoAdd = () => {
    setOpenVideoAddDialog(false);
  };

  const formatDate = (dateString: string | number | Date) => {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
    const day = ("0" + date.getUTCDate()).slice(-2);
    const hours = ("0" + date.getUTCHours()).slice(-2);
    const minutes = ("0" + date.getUTCMinutes()).slice(-2);

    return `${year}/${month}/${day} ${hours}:${minutes}`;
  };

  const convertToCSV = (data: any[]) => {
    let jsonData: { email: any; lectureName: any; lectureStatus: any }[] = [];
    let maxVideosLength = 0;
    data.forEach((item: { videos: string | any[] }) => {
      if (item.videos.length > maxVideosLength) {
        maxVideosLength = item.videos.length;
      }
    });
    let headers = [
      { label: "メールアドレス", key: "email" },
      { label: "講座名", key: "lectureName" },
      { label: "講座状況", key: "lectureStatus" },
    ];
    for (let i = 0; i < maxVideosLength; i++) {
      headers.push({ label: `動画${i + 1}名`, key: `video${i + 1}_name` });
      headers.push({
        label: `動画${i + 1}視聴開始時刻`,
        key: `video${i + 1}_startTime`,
      });
      headers.push({
        label: `動画${i + 1}視聴終了時刻`,
        key: `video${i + 1}_endTime`,
      });
      headers.push({
        label: `動画${i + 1}テスト結果`,
        key: `video${i + 1}_testResult`,
      });
      headers.push({
        label: `動画${i + 1}テスト回答時刻`,
        key: `video${i + 1}_testTime`,
      });
    }
    data.forEach(
      (item: {
        email: any;
        lectureName: any;
        lectureStatus: any;
        videos: any[];
      }) => {
        let jsonItem: any = {
          email: item.email,
          lectureName: item.lectureName,
          lectureStatus: item.lectureStatus,
        };
        item.videos.forEach((video: any, index: number) => {
          jsonItem[`video${index + 1}_name`] = video.name;
          jsonItem[`video${index + 1}_startTime`] =
            video.startTime !== "-" ? formatDate(video.startTime) : "-";
          jsonItem[`video${index + 1}_endTime`] =
            video.endTime !== "-" ? formatDate(video.endTime) : "-";
          jsonItem[`video${index + 1}_testResult`] = video.testResult;
          jsonItem[`video${index + 1}_testTime`] =
            video.testTime !== "-" ? formatDate(video.testTime) : "-";
        });
        jsonData.push(jsonItem);
      }
    );
    return { jsonData, headers };
  };

  const downloadCSV = async () => {
    await get<{
      downloadData: any[];
    }>(`/api/admin/csv`)
      .then((res) => {
        const { jsonData, headers } = convertToCSV(res.downloadData);
        setCsvHeaders(headers);
        setCsvData(jsonData);
      })
      .catch(() => alert("Can't get csv download from db properly."));
  };

  useEffect(() => {
    //受講者がいないとcsvダウンロードができない
    if (csvHeaders.length > 0 && csvData.length > 0) {
      csvLink.current?.link?.click();
    }
  }, [csvHeaders, csvData, csvLink]);

  const buttons = [
    {
      icon: <CreateNewFolderIcon sx={{ mr: 1 }} />,
      label: "講座追加",
      action: handleOpenLectureAdd,
    },
    {
      icon: <PostAddIcon sx={{ mr: 1 }} />,
      label: "動画追加",
      action: handleOpenVideoAdd,
    },
    {
      icon: <DownloadIcon sx={{ mr: 1 }} />,
      label: "csvダウンロード",
      action: downloadCSV,
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 1000,
        bgcolor: "background.paper",
        mr: 4,
        ml: 4,
      }}
    >
      <Box sx={{ display: "flex", mt: 4, mb: 2 }}>
        <Typography
          sx={{ fontWeight: "bold", width: "50%" }}
          variant="h4"
          component="div"
        >
          講座一覧
        </Typography>
        <Box sx={{ width: "50%" }}>
          {buttons.map((button, index) => (
            <Button
              variant="contained"
              sx={{
                mr: 2,
                color: "white",
                backgroundColor: "#d7b27a",
                borderRadius: "20px",
                "&:hover": {
                  backgroundColor: "#D7B27A",
                  transform: "scale(1.025)",
                },
                fontWeight: "bold",
              }}
              onClick={button.action}
            >
              {React.cloneElement(button.icon, { sx: { mr: 1 } })}
              {button.label}
            </Button>
          ))}
          <CSVLink
            headers={csvHeaders}
            data={csvData}
            filename={"user_logs.csv"}
            target="_blank"
            ref={csvLink}
            className="hidden"
          />
        </Box>
      </Box>
      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{ borderRadius: "20px" }}
      >
        <Table
          sx={{
            minWidth: 300,
            [`& .${tableCellClasses.root}`]: {
              borderLeft: "none",
              borderRight: "none",
            },
          }}
          aria-label="simple table"
        >
          <TableHead sx={{ backgroundColor: "#E5E4E2" }}>
            <TableRow>
              <TableCell align="center" sx={{ width: "10%" }} />
              <TableCell align="center" sx={{ width: "45%" }}>
                講座名
              </TableCell>
              <TableCell align="center" sx={{ width: "15%" }}>
                動画数
              </TableCell>
              <TableCell align="center" sx={{ width: "15%" }}>
                受講状況
              </TableCell>
              <TableCell align="center" sx={{ width: "15%" }}>
                未完了者一覧
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{tbody}</TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                labelRowsPerPage="表示件数:"
                rowsPerPageOptions={[
                  { label: "10件", value: 10 },
                  { label: "50件", value: 50 },
                  {
                    label: "全て",
                    value: lectureData ? lectureData.length : 0,
                  },
                ]}
                count={lectureData ? lectureData.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Card
        variant="outlined"
        sx={{
          mt: 2,
          width: "100%",
          backgroundColor: "#E5E4E2",
          borderRadius: "20px",
        }}
      >
        <CardContent>
          <Typography sx={{ mb: 2 }} variant="h5" component="div">
            【備考】
          </Typography>
          <Typography sx={{ mb: 2 }} component="div">
            ・各講座内の動画の順番はドラッグ&ドロップで変更することができます。
          </Typography>
        </CardContent>
      </Card>
      <Snackbar
        open={videoOrderSuccess}
        autoHideDuration={2000}
        onClose={() => setVideoOrderSuccess(false)}
      >
        <Alert
          severity="success"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setVideoOrderSuccess(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mr: 4, ml: 4 }}
        >
          動画の順番が正常に変更されました。
        </Alert>
      </Snackbar>
      <Snackbar
        open={videoOrderFail}
        autoHideDuration={2000}
        onClose={() => setVideoOrderFail(false)}
      >
        <Alert
          severity="success"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setVideoOrderFail(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mr: 4, ml: 4 }}
        >
          動画の順番を変更できませんでした。
        </Alert>
      </Snackbar>
      <LectureAdd
        open={openLectureAddDialog}
        onClose={handleCloseLectureAdd}
        onSuccess={() => {
          refresh();
        }}
      />
      <VideoAdd
        open={openVideoAddDialog}
        onClose={handleCloseVideoAdd}
        onSuccess={() => {
          refresh();
        }}
        lectureData={lectureData}
      />
      <UnAttendedDialog
        open={openLectureUnattendedDialog}
        onClose={handleCloseLectureUnattendedDialog}
        aria-labelledby="lecture-unattended-dialog"
      >
        <DialogTitle
          sx={{
            backgroundColor: "#d7b27a",
            color: "white",
            fontWeight: "bold",
            fontSize: "23px",
          }}
          id="lecture-unattended-dialog-title"
          align="center"
        >
          <PersonIcon
            fontSize="large"
            sx={{ verticalAlign: "middle", mr: 1 }}
          />
          講座未完了者一覧
          <br />
          〜講座名:『{lectureName}』〜
        </DialogTitle>
        <IconButton
          aria-label="close-lecture-unattended-dialog"
          onClick={handleCloseLectureUnattendedDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          dividers
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          <TableContainer
            component={Paper}
            variant="outlined"
            sx={{ borderRadius: "20px" }}
          >
            <Table
              sx={{
                minWidth: 300,
                [`& .${tableCellClasses.root}`]: {
                  borderLeft: "none",
                  borderRight: "none",
                },
              }}
              aria-label="simple table"
            >
              <TableHead sx={{ backgroundColor: "#E5E4E2" }}>
                <TableRow>
                  <TableCell align="center" sx={{ width: "30%" }}>
                    メールアドレス
                  </TableCell>
                  <TableCell align="center" sx={{ width: "70%" }}>
                    未完了動画
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {unattendedLectureData ? (
                  unattendedLectureData
                    .slice(
                      pageLectureUnattended * rowsPerPageLectureUnattended,
                      pageLectureUnattended * rowsPerPageLectureUnattended +
                        rowsPerPageLectureUnattended
                    )
                    .map((row: any) => (
                      <TableRow key={row.id}>
                        <TableCell align="center" sx={{ width: "30%" }}>
                          <Typography variant="body2" fontWeight="bold">
                            {row.email}
                          </Typography>
                        </TableCell>
                        <TableCell align="center" sx={{ width: "70%" }}>
                          <Typography variant="body2" fontWeight="bold">
                            {row.videos ? row.videos : "動画を追加してください"}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={4}>
                      データがありません
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    labelRowsPerPage="表示件数:"
                    rowsPerPageOptions={[
                      { label: "5件", value: 5 },
                      { label: "10件", value: 10 },
                      {
                        label: "全て",
                        value: unattendedLectureData
                          ? unattendedLectureData.length
                          : 0,
                      },
                    ]}
                    count={
                      unattendedLectureData ? unattendedLectureData.length : 0
                    }
                    rowsPerPage={rowsPerPageLectureUnattended}
                    page={pageLectureUnattended}
                    onPageChange={handleChangePageLectureUnattended}
                    onRowsPerPageChange={
                      handleChangeRowsPerPageLectureUnattended
                    }
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </DialogContent>
      </UnAttendedDialog>
      <UnAttendedDialog
        open={openVideoUnattendedDialog}
        onClose={handleCloseVideoUnattendedDialog}
        aria-labelledby="video-unattended-dialog"
      >
        <DialogTitle
          sx={{
            backgroundColor: "#d7b27a",
            color: "white",
            fontWeight: "bold",
            fontSize: "23px",
          }}
          id="video-video-unattended-dialog-title"
          align="center"
        >
          <PersonIcon
            fontSize="large"
            sx={{ verticalAlign: "middle", mr: 1 }}
          />
          動画未完了者一覧
          <br />
          〜講座名：『{lectureName}』, 動画名：『{lectureVideoName}』〜
        </DialogTitle>
        <IconButton
          aria-label="close-video-unattended-dialog"
          onClick={handleCloseVideoUnattendedDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          dividers
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          <TableContainer
            component={Paper}
            variant="outlined"
            sx={{ borderRadius: "20px" }}
          >
            <Table
              sx={{
                minWidth: 300,
                [`& .${tableCellClasses.root}`]: {
                  borderLeft: "none",
                  borderRight: "none",
                },
              }}
              aria-label="simple table"
            >
              <TableHead sx={{ backgroundColor: "#E5E4E2" }}>
                <TableRow>
                  <TableCell align="center" sx={{ width: "70%" }}>
                    メールアドレス
                  </TableCell>
                  <TableCell align="center" sx={{ width: "15%" }}>
                    動画視聴状況
                  </TableCell>
                  <TableCell align="center" sx={{ width: "15%" }}>
                    回答状況
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {unattendedVideoData ? (
                  unattendedVideoData
                    .slice(
                      pageVideoUnattended * rowsPerPageVideoUnattended,
                      pageVideoUnattended * rowsPerPageVideoUnattended +
                        rowsPerPageVideoUnattended
                    )
                    .map((row: any) => (
                      <TableRow key={row.id}>
                        <TableCell align="center" sx={{ width: "70%" }}>
                          <Typography variant="body2" fontWeight="bold">
                            {row.email}
                          </Typography>
                        </TableCell>
                        <TableCell align="center" sx={{ width: "15%" }}>
                          <Typography variant="body2" fontWeight="bold">
                            {row.videoStatus ? "視聴済" : "未視聴"}
                          </Typography>
                        </TableCell>
                        <TableCell align="center" sx={{ width: "15%" }}>
                          <Typography variant="body2" fontWeight="bold">
                            未受験
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={4}>
                      データがありません
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    labelRowsPerPage="表示件数:"
                    rowsPerPageOptions={[
                      { label: "5件", value: 5 },
                      { label: "10件", value: 10 },
                      {
                        label: "全て",
                        value: unattendedVideoData
                          ? unattendedVideoData.length
                          : 0,
                      },
                    ]}
                    count={unattendedVideoData ? unattendedVideoData.length : 0}
                    rowsPerPage={rowsPerPageVideoUnattended}
                    page={pageVideoUnattended}
                    onPageChange={handleChangePageVideoUnattended}
                    onRowsPerPageChange={handleChangeRowsPerPageVideoUnattended}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </DialogContent>
      </UnAttendedDialog>
      <VideoDialog
        open={openVideoDialog}
        onClose={handleCloseVideoDialog}
        aria-labelledby="video-dialog"
      >
        <DialogTitle
          sx={{
            backgroundColor: "#d7b27a",
            color: "white",
            fontWeight: "bold",
            fontSize: "23px",
          }}
          id="video-dialog-title"
          align="center"
        >
          {content}
        </DialogTitle>
        <IconButton
          aria-label="close-video-dialog"
          onClick={handleCloseVideoDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          dividers
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          <div className="player-wrapper">
            <ReactPlayer
              url={videoUrl!}
              className="react-player"
              controls
              width="100%"
              height="100%"
              onError={(e) => onErrorTime(e.target.error)}
            />
          </div>
        </DialogContent>
      </VideoDialog>
    </Box>
  );
}

import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import { TableFooter } from "@mui/material";
import { useUserFetch } from "../api/fetch";

export default function LoginLogoutList() {
  const [data, setData] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { get } = useUserFetch();

  useEffect(() => {
    get<{
      message: string;
      data: any[];
    }>(`/api/user/action`)
      .then((res) => {
        console.log(res.message);
        setData(res.data);
      })
      .catch(() => alert("Can't get data from db properly."));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (_: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function formatDate(dateString: string) {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
    const day = ("0" + date.getUTCDate()).slice(-2);
    const hours = ("0" + date.getUTCHours()).slice(-2);
    const minutes = ("0" + date.getUTCMinutes()).slice(-2);

    return `${year}/${month}/${day} ${hours}:${minutes}`;
  }

  return (
    <Box sx={{ width: "100%", maxWidth: 1000, bgcolor: "background.paper" }}>
      <Typography
        sx={{ mt: 4, mb: 2, mr: 4, ml: 4, fontWeight: "bold" }}
        variant="h4"
        component="div"
      >
        操作履歴
      </Typography>
      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{ mr: 4, ml: 4, borderRadius: "20px" }}
      >
        <Table
          sx={{
            minWidth: 300,
            [`& .${tableCellClasses.root}`]: {
              borderLeft: "none",
              borderRight: "none",
            },
          }}
          aria-label="simple table"
        >
          <TableHead sx={{ backgroundColor: "#E5E4E2" }}>
            <TableRow>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                操作
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                詳細
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                操作日時
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              ? data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: any) => (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center">
                        {row.action !== null
                          ? row.action
                          : "-"}
                      </TableCell>
                      <TableCell align="center">
                        {row.detail !== null
                          ? row.detail
                          : "-"}
                      </TableCell>
                      <TableCell align="center">
                        {row.createdAt !== null
                          ? formatDate(row.createdAt)
                          : "-"}
                      </TableCell>
                    </TableRow>
                  ))
              : null}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                labelRowsPerPage="表示件数:"
                rowsPerPageOptions={[
                  { label: "5件", value: 5 },
                  { label: "10件", value: 10 },
                  { label: "50件", value: 50 },
                  { label: "全て", value: data ? data.length : 0 },
                ]}
                count={data ? data.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
}

import React from "react";
import sanitizeHtml from 'sanitize-html';

type SanitizedMessageProps = {
  message: string;
};

const SanitizedMessage: React.FC<SanitizedMessageProps> = ({ message }) => {
  const sanitizedHtml = sanitizeHtml(message, {
    allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ul', 'ol', 'li', 'blockquote', 'pre', 'code', 'br', 'hr', 'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'strike', 'del', 'u', 'img'],
    allowedAttributes: {
      'a': ['href'],
      'img': ['src']
    }
  });

  return <span dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
};

export default SanitizedMessage;

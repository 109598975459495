import React, { ReactNode } from "react";

import styled from "@mui/material/styles/styled";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { IconButton, IconButtonProps } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const AddDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    maxWidth: "500px",
    maxHeight: "550px",
    width: "100%",
    height: "100%",
    borderRadius: "20px",
  },
}));

export const AddDialogTitle = styled(DialogTitle)(() => ({
  backgroundColor: "#d7b27a",
  color: "white",
  fontWeight: "bold",
  fontSize: "23px",
  textAlign: "center",
}));

export const AddDialogCloseButton: React.FC<IconButtonProps> = (props) => (
  <IconButton
    {...props}
    sx={{
      position: "absolute",
      right: 8,
      top: 8,
      color: "white",
    }}
  >
    <CloseIcon />
  </IconButton>
);

export const AddDialogContent: React.FC<{ children: ReactNode }> = ({
  children,
}) => (
  <DialogContent
    dividers
    sx={{ display: "flex", flexDirection: "column", gap: 2 }}
  >
    {children}
  </DialogContent>
);

export const AddDialogAddButton: React.FC<{
  onClick: () => void;
  disabled: boolean;
  message?: string;
}> = ({ onClick, disabled, message = "追加する" }) => (
  <Button
    variant="contained"
    onClick={onClick}
    disabled={disabled}
    sx={{
      width: "50%",
      color: "#d7b27a",
      alignSelf: "center",
      backgroundColor: "white",
      borderRadius: "16px",
      border: "2px solid #d7b27a",
      fontSize: "15px",
      "&:hover": {
        color: "white",
        transform: "scale(1.1)",
        backgroundColor: "#d7b27a",
      },
    }}
  >
    {message}
  </Button>
);

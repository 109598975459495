import styled from "@mui/material/styles/styled";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Typography from "@mui/material/Typography";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

type VideoSelectProps = {
  videoName: string;
  videoSize: string;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const VideoSelect: React.FC<VideoSelectProps> = ({
  videoName,
  videoSize,
  disabled = false,
  onChange,
}) => {
  return (
    <>
      <Button
        disabled={disabled}
        sx={{
          mt: 2,
          borderRadius: "20px",
          width: "50%",
          alignSelf: "center",
          "&:hover": {
            transform: "scale(1.1)",
          },
        }}
        component="label"
        variant="contained"
        startIcon={<CloudUploadIcon />}
      >
        動画をアップロードする
        <VisuallyHiddenInput type="file" onChange={onChange} />
      </Button>
      <Typography
        component="div"
        sx={{
          alignSelf: "center",
        }}
      >
        {videoName !== "" && Number(videoSize) !== 0
          ? "動画名：" + videoName + "(大きさ：" + videoSize + ")"
          : "(動画がアップロードされていません)"}
      </Typography>
    </>
  );
};

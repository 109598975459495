import React from "react";

import PostAddIcon from "@mui/icons-material/PostAdd";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import CircularProgress from "@mui/material/CircularProgress";

import {
  AddDialog,
  AddDialogAddButton,
  AddDialogCloseButton,
  AddDialogContent,
  AddDialogTitle,
} from "./AddDialog";
import { useAdminFetch } from "../../api/fetch";
import { Alert } from "./Alert";
import { StyledFormControl } from "../StyledFormControl";
import { StyledTextField } from "../StyledTextField";
import { VideoSelect } from "./VideoSelect";

type VideoAddProps = {
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  lectureData: any[];
};

export const VideoAdd: React.FC<VideoAddProps> = ({
  open,
  onClose,
  onSuccess,
  lectureData,
}) => {
  const [lectureId, setLectureId] = React.useState("");
  const [video, setVideo] = React.useState<{
    name: string;
    size: string;
    file: File | null;
  }>({
    name: "",
    size: "",
    file: null,
  });
  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [alert, setAlert] = React.useState({
    lecture: "",
    video: "",
  });
  const { post, put } = useAdminFetch();

  const hasError = (alert: any) => alert.lecture !== "" && alert.video !== "";

  const clear = () => {
    setAlert({
      lecture: "",
      video: "",
    });
    setLectureId("");
    setVideo({
      name: "",
      size: "",
      file: null,
    });
  };

  const createVideo = async () => {
    const alertInternal = alert;
    if (lectureId === "") {
      alertInternal.lecture = "講座が選択されていません";
    }

    if (video.name === "") {
      alertInternal.video = "動画名が入力されていません";
    }

    if (video.file === null) {
      alertInternal.video = "動画が選択されていません";
    }

    if (hasError(alertInternal)) {
      setAlert(alertInternal);
      return;
    }

    setLoading(true);
    const res = await post<{
      message: string;
      success: string;
      uploadUrl: string;
    }>(`/api/admin/video/create`, {
      body: JSON.stringify({
        text_from_react: video.name,
        id_from_react: lectureId,
        file_type_from_react: video.file!.type,
      }),
    });
    console.log(res.message);
    if (res.success === "vc1") {
      const uploadResponse = await put<number>(res.uploadUrl, {
        body: video.file,
        headers: {
          "Content-Type": video.file!.type,
        },
      });
      setLoading(false);
      if (uploadResponse === 200) {
        setSuccess(true);
        clear();
        onSuccess?.();
        return;
      }
      setAlert({
        ...alert,
        video: "動画のアップロードに失敗しました",
      });
      return;
    }
    if (res.success === "vc3") {
      setAlert({
        ...alert,
        video: "すでにその動画名は存在しています",
      });
      return;
    }
    setAlert({
      ...alert,
      video: "動画のアップロードに失敗しました",
    });
  };

  return (
    <>
      <AddDialog
        open={open}
        onClose={onClose}
        aria-labelledby="video-add-dialog"
      >
        <AddDialogTitle>
          <PostAddIcon
            fontSize="large"
            sx={{ verticalAlign: "middle", mr: 1 }}
          />
          動画追加
        </AddDialogTitle>
        <AddDialogCloseButton
          aria-label="close-video-add-dialog"
          onClick={onClose}
        />
        <AddDialogContent>
        {loading && <CircularProgress />}
          <StyledFormControl
            variant="standard"
            sx={{ m: 1, minWidth: 250 }}
            required
            error={hasError(alert)}
          >
            <InputLabel>講座名</InputLabel>
            <Select
              value={lectureId}
              onChange={(e) => {
                setLectureId(e.target.value);
                setAlert({
                  ...alert,
                  lecture: "",
                });
              }}
              disabled={loading}
            >
              {lectureData
                ? lectureData.map((row: any) => (
                    <MenuItem key={row.id} value={row.id}>
                      {row.lectureName}
                    </MenuItem>
                  ))
                : null}
            </Select>
            <FormHelperText id="video-lecture-error">
              {alert.lecture}
            </FormHelperText>
          </StyledFormControl>
          <StyledTextField
            label="動画名"
            variant="standard"
            onChange={(e) => {
              setVideo({
                ...video,
                name: e.target.value,
              });
              setAlert({
                ...alert,
                video: "",
              });
            }}
            sx={{ m: 1, minWidth: 250 }}
            value={video.name}
            required
            error={alert.video !== ""}
            helperText={alert.video}
            disabled={loading}
          />
          <VideoSelect
            videoName={video.name}
            videoSize={video.size}
            disabled={loading}
            onChange={(e) => {
              const selectedFile = e.target.files![0];
              if(!selectedFile) {
                return;
              }
              setVideo({
                name: selectedFile.name,
                size: Math.floor(selectedFile.size / 1024) + "kb",
                file: selectedFile,
              });
            }}
          />
          <AddDialogAddButton
            onClick={createVideo}
            disabled={loading}
            message="提出する"
          />
        </AddDialogContent>
      </AddDialog>
      <Alert
        open={success || hasError(alert)}
        message={
          success
            ? "動画を追加しました"
            : [alert.lecture, alert.video].filter((v) => !!v).join("\n")
        }
        severity={success ? "success" : "error"}
        onClose={() => {
          setSuccess(false);
          setAlert({
            lecture: "",
            video: "",
          });
        }}
      />
    </>
  );
};

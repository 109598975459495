import React from "react";

import Snackbar from "@mui/material/Snackbar";
import MAlert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

type AlertProps = {
  open: boolean;
  onClose: () => void;
  message: string;
  severity: "error" | "warning" | "info" | "success" | undefined;
};

export const Alert: React.FC<AlertProps> = ({
  open,
  onClose,
  message,
  severity,
}) => (
  <Snackbar open={open}>
    <MAlert
      severity={severity}
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={onClose}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
      sx={{ mr: 4, ml: 4 }}
    >
      {message}
    </MAlert>
  </Snackbar>
);

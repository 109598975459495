import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import TopicIcon from "@mui/icons-material/Topic";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useState, useEffect } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Grid from "@mui/material/Grid";
import { useUserFetch } from "../api/fetch";
import Alert from "@mui/material/Alert";
import SanitizedMessage from '../components/SanitizedMessage';

const Color = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
}));

export default function MyPage() {
  const [lectureData, setLectureData] = useState<any[]>([]);
  const [warningMessage, setWarningMessage] = useState<string>("");

  const { get } = useUserFetch();

  useEffect(() => {
    const fetchLectures = get<{ message: string; lectures: any[] }>("/api/user/lectures");
    const fetchWarningMessage = get<{ message: string }>("/api/user/warning");

    Promise.all([fetchLectures, fetchWarningMessage])
      .then(([lectureRes, warningMessageRes]) => {
        console.log(lectureRes.message);
        setLectureData(lectureRes.lectures);
        setWarningMessage(warningMessageRes.message);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ width: "100%", maxWidth: 2000, bgcolor: "background.paper" }}>
      <Typography
        sx={{ mt: 4, mb: 2, mr: 4, ml: 4, fontWeight: "bold" }}
        variant="h4"
        component="div"
      >
        マイページ
      </Typography>
      <Alert 
        severity="warning" 
        sx={{mr: 4, ml: 4 }}
      >
        <SanitizedMessage message={warningMessage} />
      </Alert>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Card
            variant="outlined"
            sx={{ mt: 2, mr: 4, ml: 4, borderRadius: "20px" }}
          >
            <CardContent>
              <Typography
                sx={{ mb: 2, fontWeight: "bold" }}
                variant="h6"
                component="div"
              >
                講座一覧
              </Typography>
              <Color>
                <List>
                  {lectureData ? (
                    lectureData.map((row: any) => (
                      <ListItem key={row.id} disablePadding>
                        <ListItemButton
                          component={Link}
                          to={"/lecture/" + row.id}
                        >
                          <TopicIcon />
                          <ListItemText primary={row.lectureName} />
                          <KeyboardArrowRightIcon />
                        </ListItemButton>
                      </ListItem>
                    ))
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      講座がありません
                    </div>
                  )}
                </List>
              </Color>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card
            variant="outlined"
            sx={{ mt: 2, mr: 4, ml: 4, borderRadius: "20px" }}
          >
            <CardContent>
              <Typography
                sx={{ mb: 2, fontWeight: "bold" }}
                variant="h6"
                component="div"
              >
                操作履歴
              </Typography>
              <Color>
                <List>
                  <ListItem key={"ActionList"} disablePadding>
                    <ListItemButton component={Link} to="/ActionList">
                      <AccessTimeIcon />
                      <ListItemText primary={"操作履歴"} />
                      <KeyboardArrowRightIcon />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Color>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

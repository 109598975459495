import React from "react";

import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import CircularProgress from "@mui/material/CircularProgress";

import { StyledTextField } from "../../components/StyledTextField";
import {
  AddDialog,
  AddDialogAddButton,
  AddDialogCloseButton,
  AddDialogContent,
  AddDialogTitle,
} from "./AddDialog";
import { useAdminFetch } from "../../api/fetch";
import { Alert } from "./Alert";

type LectureAddProps = {
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
};

export const LectureAdd: React.FC<LectureAddProps> = ({ open, onClose, onSuccess }) => {
  const [textLecture, setTextLecture] = React.useState("");
  const [createLectureError, setCreateLectureError] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { post } = useAdminFetch();

  const createLecture = async () => {
    if (textLecture === "") {
      setCreateLectureError("講座名が入力されていません");
      return;
    }

    const res = await post<{
      message: string;
      success: string;
    }>(`/api/admin/lecture/create`, {
      body: JSON.stringify({
        text_lecture_from_react: textLecture,
      }),
    });
    console.log(res.message);
    setLoading(false);
    if (res.success === "lc1") {
      setSuccess(true);
      onSuccess?.();
      setTextLecture("");
    } else if (res.success === "lc2") {
      setCreateLectureError("すでにその講座名は存在しています");
    } else {
      setCreateLectureError("エラーが発生しました");
    }
  };

  return (
    <>
      <AddDialog
        open={open}
        onClose={onClose}
        aria-labelledby="lecture-add-dialog"
      >
        <AddDialogTitle>
          <CreateNewFolderIcon
            fontSize="large"
            sx={{ verticalAlign: "middle", mr: 1 }}
          />
          講座追加
        </AddDialogTitle>
        <AddDialogCloseButton
          aria-label="close-lecture-add-dialog"
          onClick={onClose}
        />
        <AddDialogContent>
          {loading && <CircularProgress />}
          <StyledTextField
            label="講座名"
            variant="standard"
            onChange={(e) => {
              setTextLecture(e.target.value);
              setCreateLectureError("");
            }}
            value={textLecture}
            sx={{ m: 1, minWidth: 250 }}
            required
            error={Boolean(createLectureError)}
            helperText={createLectureError}
            disabled={loading}
          />
          <AddDialogAddButton onClick={createLecture} disabled={loading} />
        </AddDialogContent>
      </AddDialog>
      <Alert
        open={success || "" !== createLectureError}
        message={success ? "講座を追加しました" : createLectureError}
        severity={success ? "success" : "error"}
        onClose={() => {
          setSuccess(false);
          setCreateLectureError("");
        }}
      />
    </>
  );
};
